<template>
  <div>
    <validation-observer ref="formValidation">
      <b-form>
        <b-row class="align-content-center mx-auto d-flex flex-column">
<!--          <b-col-->
<!--            lg="8"-->
<!--            md="10"-->
<!--            sm="12"-->
<!--          >-->
<!--            <b-form-group-->
<!--              label="Host de Hosting"-->
<!--              label-for="host"-->
<!--            >-->
<!--              <validation-provider-->
<!--                #default="{ errors }"-->
<!--                name="host"-->
<!--                rules="required"-->
<!--              >-->
<!--                <b-form-input-->
<!--                  id="host"-->
<!--                  v-model="host"-->
<!--                  :state="errors.length > 0 ? false:null"-->
<!--                  placeholder="Host de Hosting"-->
<!--                />-->
<!--                <small class="text-danger">{{ errors[0] }}</small>-->
<!--              </validation-provider>-->
<!--            </b-form-group>-->
<!--          </b-col>-->
<!--          <b-col-->
<!--            lg="8"-->
<!--            md="10"-->
<!--            sm="12"-->
<!--          >-->
<!--            <b-form-group-->
<!--              label="Puerto de Correo"-->
<!--              label-for="port"-->
<!--            >-->
<!--              <validation-provider-->
<!--                #default="{ errors }"-->
<!--                name="puerto"-->
<!--                rules="required"-->
<!--              >-->
<!--                <b-form-input-->
<!--                  id="port"-->
<!--                  v-model="port"-->
<!--                  :state="errors.length > 0 ? false:null"-->
<!--                  placeholder="Puerto de Correo"-->
<!--                />-->
<!--                <small class="text-danger">{{ errors[0] }}</small>-->
<!--              </validation-provider>-->
<!--            </b-form-group>-->
<!--          </b-col>-->
<!--          <b-col-->
<!--            lg="8"-->
<!--            md="10"-->
<!--            sm="12"-->
<!--          >-->
<!--            <b-form-group-->
<!--              label="Destino de Correo"-->
<!--              label-for="from"-->
<!--            >-->
<!--              <validation-provider-->
<!--                #default="{ errors }"-->
<!--                name="from"-->
<!--                rules="required|email"-->
<!--              >-->
<!--                <b-form-input-->
<!--                  id="from"-->
<!--                  v-model="from"-->
<!--                  :state="errors.length > 0 ? false:null"-->
<!--                  placeholder="Destino de Correo"-->
<!--                />-->
<!--                <small class="text-danger">{{ errors[0] }}</small>-->
<!--              </validation-provider>-->
<!--            </b-form-group>-->
<!--          </b-col>-->
<!--          <b-col-->
<!--            lg="8"-->
<!--            md="10"-->
<!--            sm="12"-->
<!--          >-->
<!--            <b-form-group-->
<!--              label="Nombre del Destino"-->
<!--              label-for="name"-->
<!--            >-->
<!--              <validation-provider-->
<!--                #default="{ errors }"-->
<!--                name="nombre"-->
<!--                rules="required"-->
<!--              >-->
<!--                <b-form-input-->
<!--                  id="name"-->
<!--                  v-model="name"-->
<!--                  :state="errors.length > 0 ? false:null"-->
<!--                  placeholder="Nombre del Destino"-->
<!--                />-->
<!--                <small class="text-danger">{{ errors[0] }}</small>-->
<!--              </validation-provider>-->
<!--            </b-form-group>-->
<!--          </b-col>-->
<!--          <b-col-->
<!--            lg="8"-->
<!--            md="10"-->
<!--            sm="12"-->
<!--          >-->
<!--            <b-form-group-->
<!--              label="Usuario del Correo"-->
<!--              label-for="username"-->
<!--            >-->
<!--              <validation-provider-->
<!--                #default="{ errors }"-->
<!--                name="usuario"-->
<!--                rules="required"-->
<!--              >-->
<!--                <b-form-input-->
<!--                  id="username"-->
<!--                  v-model="username"-->
<!--                  :state="errors.length > 0 ? false:null"-->
<!--                  placeholder="Usuario del Correo"-->
<!--                />-->
<!--                <small class="text-danger">{{ errors[0] }}</small>-->
<!--              </validation-provider>-->
<!--            </b-form-group>-->
<!--          </b-col>-->
<!--          <b-col-->
<!--            lg="8"-->
<!--            md="10"-->
<!--            sm="12"-->
<!--          >-->
<!--            <b-form-group-->
<!--              label="Contraseña de correo"-->
<!--              label-for="password"-->
<!--            >-->
<!--              <validation-provider-->
<!--                #default="{ errors }"-->
<!--                name="contraseña"-->
<!--                rules="required"-->
<!--              >-->
<!--                <b-form-input-->
<!--                  id="password"-->
<!--                  v-model="password"-->
<!--                  type="password"-->
<!--                  :state="errors.length > 0 ? false:null"-->
<!--                  placeholder="Contraseña de correo"-->
<!--                />-->
<!--                <small class="text-danger">{{ errors[0] }}</small>-->
<!--              </validation-provider>-->
<!--            </b-form-group>-->
<!--          </b-col>-->
<!--          <b-col-->
<!--            lg="8"-->
<!--            md="10"-->
<!--            sm="12"-->
<!--          >-->
<!--            <b-form-group-->
<!--              label="Encryptación de Correo"-->
<!--              label-for="encriptación"-->
<!--            >-->
<!--              <validation-provider-->
<!--                #default="{ errors }"-->
<!--                name="encryption"-->
<!--                rules="required"-->
<!--              >-->
<!--                <v-select-->
<!--                  v-model="encryption"-->
<!--                  :state="errors.length > 0 ? false:null"-->
<!--                  :dir="'ltr'"-->
<!--                  label="title"-->
<!--                  :options="option"-->
<!--                >-->
<!--                  <template #option="{ title }">-->
<!--                    <span> {{ title }}</span>-->
<!--                  </template>-->
<!--                </v-select>-->
<!--                <small class="text-danger">{{ errors[0] }}</small>-->
<!--              </validation-provider>-->
<!--            </b-form-group>-->
<!--          </b-col>-->
<!--          <b-col-->
<!--            v-if="this.id !== undefined"-->
<!--            lg="8"-->
<!--            md="10"-->
<!--            sm="12"-->
<!--          >-->
<!--            <b-form-group-->
<!--              label="Activar"-->
<!--              label-for="active"-->
<!--            >-->
<!--              <validation-provider-->
<!--                #default="{ errors }"-->
<!--                name="respaldo"-->
<!--                rules="required"-->
<!--              >-->
<!--                <b-form-radio-->
<!--                  v-model="active"-->
<!--                  :state="errors.length > 0 ? false:null"-->
<!--                  name="active"-->
<!--                  class="float-left mb-2"-->
<!--                  value="Si"-->
<!--                >-->
<!--                  Si-->
<!--                </b-form-radio>-->
<!--                <b-form-radio-->
<!--                  v-model="active"-->
<!--                  :state="errors.length > 0 ? false:null"-->
<!--                  name="active"-->
<!--                  class="float-left ml-2 mb-2"-->
<!--                  value="No"-->
<!--                >-->
<!--                  No-->
<!--                </b-form-radio>-->
<!--                <div class="w-100 float-left">-->
<!--                  <small class="text-danger">{{ errors[0] }}</small>-->
<!--                </div>-->
<!--              </validation-provider>-->
<!--            </b-form-group>-->
<!--          </b-col>-->
          <b-col
            lg="8"
            md="10"
            sm="12"
          >
            <div class="float-right text-right">
<!--              <b-button-->
<!--                v-if="id === undefined ? accessPermissions('configuration.store') : accessPermissions('configuration.update')"-->
<!--                variant="primary"-->
<!--                type="submit"-->
<!--                @click.prevent="onHandleValidationForm"-->
<!--              >-->
<!--                <feather-icon-->
<!--                  icon="SaveIcon"-->
<!--                  class="mr-50"-->
<!--                />-->
<!--                Guardar-->
<!--              </b-button>-->
            </div>
            <div
              v-if="accessPermissions('configuration.send.email.example')"
              class="float-right text-right mr-1"
            >
              <b-button
                v-b-modal.modal-send-email
                variant="success"
              >
                <feather-icon icon="MailIcon" />
                Enviar correo de prueba
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-modal
      id="modal-send-email"
      ref="send-email-modal"
      centered
      hide-backdrop
      hide-footer
      no-close-on-backdrop
      content-class="shadow"
      size="md"
      title="Enviar correo de prueba"
    >
      <validation-observer ref="formEmailValidation">
        <b-form>
          <b-row>
            <b-col
              sm="12"
            >
              <b-form-group
                label="Dirección de correo electrónico"
                label-for="emailTest"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="emailTest"
                    v-model="emailTest"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Dirección de correo electrónico"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
            >
              <b-button
                class="float-right text-right"
                variant="primary"
                type="submit"
                @click.prevent="onHandleSendEmail"
              >
                <feather-icon icon="MailIcon" />
                Enviar correo de prueba
              </b-button>
            </b-col>
            <b-col
              sm="12"
              class="mt-1 position-relative text-right align-content-end float-end"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                @click="hideModal"
              >
                Cerrar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormRadio,
  BModal,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import router from '@/router'
import ConfigServices from '../services/ConfigServices'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'FormCreateComponent',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BFormRadio,
    BModal,
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      option: [
        { title: 'ssl' },
        { title: 'tls' },
      ],
      host: '',
      port: '',
      from: '',
      name: '',
      username: '',
      password: '',
      encryption: '',
      active: '',
      emailTest: '',
      required,
      email,
    }
  },
  mounted() {
    this.encryption = this.option[0]
  },
  async created() {
    await this.onHandleEdit()
  },
  methods: {
    accessPermissions(permission){
      return permissions(permission);
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    clearForm() {
      this.host = ''
      this.port = ''
      this.from = ''
      this.name = ''
      this.username = ''
      this.password = ''
      this.encryption = ''
      this.active = ''
      this.$refs.formValidation.reset()
    },
    hideModal() {
      this.emailTest = ''
      this.$refs['send-email-modal'].hide()
    },
    async onHandleEdit() {
      if (this.id !== undefined && this.accessPermissions('configuration.show')) {
        await ConfigServices.handleEdit(this.id).then(response => {
          this.host = response.data.data.host
          this.port = response.data.data.port
          this.from = response.data.data.from
          this.name = response.data.data.name
          this.username = response.data.data.username
          this.password = response.data.data.password
          this.encryption = response.data.data.encryption === 'ssl' ? this.option[0] : this.option[1]
          this.active = response.data.data.active === 1 ? 'Si' : 'No'
        }).catch(error => this.errorResp(error))
      }
    },
    async onHandleSendEmail() {
      if (this.accessPermissions('configuration.send.email.example')) {
        await this.$refs.formEmailValidation.validate().then(success => {
          if (success) {
            const data = {
              email: this.emailTest,
            }

            ConfigServices.handleSendEmail(data).then(response => {
              if (response.data.data) {
                this.swal('La prueba de envío de correo se realizó correctamente', 'success')
                this.hideModal()
              }
            }).catch(error => this.errorResp(error))
          }
        })
      }
    },
    async onHandleValidationForm() {
      await this.$refs.formValidation.validate().then(success => {
        if (success) {
          const data = {
            host: this.host,
            port: this.port,
            from: this.from,
            name: this.name,
            username: this.username,
            password: this.password,
            encryption: this.encryption.title,
            active: this.active === 'Si' ? 1 : 0,
          }

          if (this.id === undefined && this.accessPermissions('configuration.store')) {
            ConfigServices.handleStore(data).then(response => {
              if (response.data.data) {
                this.swal('La configuración ha sido registrada correctamente', 'success')
                this.clearForm()
                router.push({ name: 'settings-config' })
              }
            }).catch(error => this.errorResp(error))
          } else if (this.id !== undefined && this.accessPermissions('configuration.update')) {
            ConfigServices.handleUpdate(this.id, data).then(response => {
              if (response.data.data) {
                this.swal('La configuración ha sido actualizada correctamente', 'success')
                this.clearForm()
                router.push({ name: 'settings-config' })
              }
            }).catch(error => this.errorResp(error))
          }
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
